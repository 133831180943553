import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'

const ArticlePage = ({ location }) =>
(
  <Layout>

    <SEO title="Summon recognised in Australia’s International Good Design Awards for Design Excellence"
      description="The Good Design Awards are the highest honour for design and innovation in the country and reward projects across 12 design disciplines and 30 subcategories."
      location={location}
    />

    <PageHeader
      title="Summon recognised in Australia’s International Good Design Awards for Design Excellence"
      text="The Good Design Awards are the highest honour for design and innovation in the country and reward projects across 12 design disciplines and 30 subcategories."
    />

    <SectionPageMargin>

      <Heading2>‘Summon - reinventing the car selling experience’ Recognised in Australia’s International Good Design Awards for Design Excellence.</Heading2>

      <Paragraph1>
        In the past few weeks, Summon has been recognised in the 2021 Online Retail Industry Awards (ORIAS), and also in the DRIVENxDESIGN Award Programs – 2021 the Melbourne Design Awards.
      </Paragraph1>

      <Paragraph1>
        ‘Summon - reinventing the car selling experience’ received a prestigious Good Design Award Winner Accolade in the Service Design category in recognition for outstanding design and innovation.
      </Paragraph1>

      <Paragraph1>
        The Good Design Awards Jury commented: <i>“A good example of a multi-sided business model and service design solution which has a clear value proposition. Very smart, well executed. This is a great idea that has been combined with a compelling user experience and solid business design model. Overall, a really impressive example of good service design and a standout project that ticks every box for good design in this category. Well done.”</i>
      </Paragraph1>

      <Paragraph1>
        <strong>About the Australian Good Design Awards</strong>
      </Paragraph1>

      <Paragraph1>
        The Australian Good Design Awards is the country’s oldest and most prestigious international awards for design and innovation with a proud history dating back to 1958. The Awards celebrate the best new products and services on the Australian and international market, excellence in architectural design, precinct design, engineering, fashion, digital and communication design, and reward new and emerging areas of design including design strategy, social impact design, design research and up-and-coming design talent in the Next Gen category.
      </Paragraph1>

      <Paragraph1>
        The 2021 Good Design Awards attracted a record number of submissions with 933 design projects evaluated by more than 70 Australian and international Jurors, including designers, engineers, architects and thought leaders. Each entry was evaluated according to a strict set of design evaluation criteria which includes good design, design innovation and design impact. Projects recognised with an Australian Good Design Award demonstrate excellence in professional design and highlight the impact a design-led approach has on business success and social and environmental outcomes.
      </Paragraph1>

      <Paragraph1>
        Dr. Brandon Gien, CEO of Good Design Australia said: “Receiving an Australian Good Design Award is testament to embedding design excellence at the heart of a product, service, place or experience. Although 2021 continues to be another challenging year, it is incredibly inspiring to see designers and businesses working together to find innovative, customer-centric design solutions to local and global challenges and to see them recognised and rewarded for their efforts through these prestigious Awards.”
      </Paragraph1>
      <Paragraph1>
        “The importance of embracing good design principles is now more important than ever as many businesses around the world have had to completely re-think their business strategies to remain competitive. The standard of design excellence represented in this year’s Awards is the best I’ve ever seen in my 25 years of running these Awards, an encouraging sign that the design sector is flourishing,” Dr. Gien went on to say.
      </Paragraph1>


    </SectionPageMargin>

    <ReadyToGetStarted />

  </Layout>

)

export default ArticlePage
